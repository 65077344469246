<template>
    <div class="mm-setting">
        <b-form autocomplete="off">
            <b-form-group
                label="Auto-MM Profit"
                label-for="take-profit"
                >
                <b-input-group append="%">
                    <b-input-group-prepend is-text>
                        <b-form-checkbox switch class="mr-n2" v-model="lpIsAuto">
                            <span class="sr-only">Switch for following text input</span>
                        </b-form-checkbox>
                    </b-input-group-prepend>
                    <b-form-input :readonly="!lpIsAuto" id="take-profit" aria-label="Text input with switch" placeholder="Enter ROI" v-model="lpROI"></b-form-input>
                    <b-input-group-append>
                    </b-input-group-append>
                </b-input-group>
                <b-button variant="secondary" class="mt-3 btn-apply btn-sm" @click="setMarketMakerConfig()">Apply</b-button>
            </b-form-group>
        </b-form>
    </div>
</template>
<script>
import { FTMarket } from '@baza/sdk'

export default {
    name: 'MMSetting',
    data: () => ({
        lpIsAuto: false,
        lpROI: 0,
        market: {},
    }),
    props: [ 'baseLink', 'quoteLink'],
    created: async function () {
        this.market = await FTMarket.setMarket(this.baseLink, this.quoteLink, this.provider)
        this.address = await this.provider.getSigner().getAddress()

        let lpROI = await this.market.getMarketMakerAutoROI(this.address)
        this.lpROI = lpROI.toNumber() / 10
        if (this.lpROI > 0) {
            this.lpIsAuto = true
        }

    }, 
    methods: {
        async setMarketMakerConfig() {
            if (!this.lpIsAuto) {
                this.lpROI = 0
            }
            if (this.lpROI == 0) {
                this.lpIsAuto = false
            }
            let tx = await this.market.setMarketMakerConfig(this.lpROI * 10)
            this.$bvToast.show('tx-confirm')
            await tx.wait()
            this.$emit('update-success', {
                txHash: tx.hash
            })
            this.$bvToast.show('tx-success')
            this.$bvToast.hide('tx-confirm')
        }
    }
};
</script>

<style lang="scss">
    .mm-setting{
        .btn-apply{
            background: #FEBC11;
            color: white;
            border-radius: 30px;
            border-color: #FEBC11;
            &:hover, &:focus {
                background: white;
                color: #FEBC11;
                border-color: #FEBC11;
                box-shadow: none;
            }

        }
    }
</style>
