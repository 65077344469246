import { ethers } from 'ethers'
import { constants } from '@baza/sdk'
import urljoin from 'url-join'
import store from './stores'

const NETWORKS = constants.NETWORKS
const getFtLink = (chainId, ftAddress) => {
    const network = NETWORKS.find(n => (n.chainId === '0x' + chainId.toString(16))) || {}
    if (network.nativeCurrency.wrappedToken === ftAddress) {
        return network.nativeCurrency.symbol
    }
    return ftAddress
}

const getFTAddressFromLink = (chainId, ft) => {
    let ftAddress = ft
    const network = NETWORKS.find(n => (n.chainId === '0x' + chainId.toString(16))) || {}
    if (network.nativeCurrency.wrappedToken === ft) {
        ftAddress = network.nativeCurrency.wrappedToken
    }

    let tokens = store.getTokens()
    let f = tokens.find(t => {
        return (t.symbol === ft)
            && (t.standard === 'ERC20') && (t.chainId === parseInt(chainId))
    })

    ftAddress = (f || {}).address || ftAddress

    return ftAddress
}

const getNFTAddressFromLink = (chainId, nft) => {
    let nftAddress = nft

    let tokens = store.getTokens()
    let n = tokens.find(t => {
        return (t.symbol === nft)
            && (t.standard === 'ERC721') && (t.chainId === parseInt(chainId))
    })

    nftAddress = (n || {}).address || nftAddress

    return nftAddress
}

const checkFTTokenAlert = (chainId, ft) => {
    let b = 0
    if (!ft) return b
    let tokens = store.getTokens()
    let f = tokens.find(t => {
        return (t.address === ft)
            && (t.standard === 'ERC20') && (t.chainId === parseInt(chainId))
    })

    b = ((f || {}).address) ? b : b + 1
    return b
}

const checkNFTTokenAlert = (chainId, nft) => {
    let b = 0
    if (!nft) return b
    let tokens = store.getTokens()
    let n = tokens.find(t => {
        return (t.address === nft)
            && (t.standard === 'ERC721') && (t.chainId === parseInt(chainId))
    })

    b = ((n || {}).address) ? b : b + 2
    return b
}

const truncate = (fullStr, strLen) => {
    if (fullStr.length <= strLen) return fullStr

    const separator = '...'

    let sepLen = separator.length
    let charsToShow = strLen - sepLen
    let frontChars = Math.ceil(charsToShow / 2)
    let backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars)
}

const bigToCommon = (unit, decimals = 0, fixed = 6) => {
    unit = ethers.BigNumber.from(unit)
    if (unit.lte(ethers.BigNumber.from(0)) && unit.gte(ethers.BigNumber.from(-1))) {
        unit = ethers.BigNumber.from(0)
    }
    return ethers.utils.commify((+ethers.utils.formatUnits(unit || 0, decimals || 0) || 0).toFixed(fixed))
}

const numberToCommon = (unit, fixed = 6) => {
    return ethers.utils.commify((+unit || 0).toFixed(fixed))
}

function formatLabel (labelValue, fixed = 1) {
    return Math.abs(Number(labelValue)) >= 1.0e+12
        ? (Math.abs(Number(labelValue)) / 1.0e+12).toFixed(fixed) + 'T'
        : Math.abs(Number(labelValue)) >= 1.0e+9
        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(fixed) + 'B'
        : Math.abs(Number(labelValue)) >= 1.0e+6
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(fixed) + 'M'
        : Math.abs(Number(labelValue)) >= 1.0e+3
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(fixed) + 'K'
        : (Math.abs(Number(labelValue))).toFixed(fixed)
}

function calROI (tvl, total) {
    if (total.eq(0)) return bigToCommon(0, 6, 2)
    return bigToCommon(tvl.div(total.div(1e+6)).sub(1e+6).mul(100), 6, 2)
}

function feeToCommon(fee) {
    fee = ethers.BigNumber.from(fee)
    return bigToCommon(fee.mul(1e+6).div(10), 6, 2)
}

function bigToLabel (unit, decimals, fixed = 1) {
    return formatLabel(+ethers.utils.formatUnits(unit, decimals), fixed)
}

function priceToLabel (unit, decimals, fixed = 1) {
    return (!unit.eq(0)) ? formatLabel(+ethers.utils.formatUnits(unit, decimals), fixed) : '--'
}

function addressLink (chainId, addr) {
    const network = NETWORKS.find(n => (n.chainId === '0x' + chainId.toString(16))) || {}
    return urljoin(network.blockExplorerUrls[0],`address/${addr}`)
}

function priceToTotal(amount, price){
    return ethers.BigNumber.from(amount).mul(price)
}

function txLink (chainId, hash) {
    const network = NETWORKS.find(n => (n.chainId === '0x' + chainId.toString(16))) || {}
    return urljoin(network.blockExplorerUrls[0], `/tx/${hash}`)
}

function blockLink (chainId, blockNumber) {
    const network = NETWORKS.find(n => (n.chainId === '0x' + chainId.toString(16))) || {}
    return urljoin(network.blockExplorerUrls[0], `/block/${blockNumber}`)
}

function sortPH2L (askPrices, result) {
    result.forEach(r => {
        let id = Object.keys(askPrices).find(k => (r.id == k))
        if (id) {
            r.price = askPrices[id]
        } else {
            r.price = ethers.BigNumber.from(0)
        }
    })
    result.sort((a, b) => {
        if (ethers.BigNumber.from(a.price).gt(ethers.BigNumber.from(b.price))) return -1
        if (ethers.BigNumber.from(a.price).eq(ethers.BigNumber.from(b.price))) return 0
        return 1
    })

}

function sortPL2H (askPrices, result) {
    result.forEach(r => {
        let id = Object.keys(askPrices).find(k => (r.id == k))
        if (id) {
            r.price = askPrices[id]
        } else {
            r.price = ethers.constants.MaxInt256
        }
    })
    result.sort((a, b) => {
        if (ethers.BigNumber.from(a.price).lt(ethers.BigNumber.from(b.price))) return -1
        if (ethers.BigNumber.from(a.price).eq(ethers.BigNumber.from(b.price))) return 0
        return 1
    })
}

function sortOH2L (bidPrices, result) {
    result.forEach(r => {
        let id = Object.keys(bidPrices).find(k => (r.id == k))
        if (id) {
            r.topBid = bidPrices[id]
        } else {
            r.topBid = ethers.BigNumber.from(0)
        }
    })
    result.sort((a, b) => {
        if (ethers.BigNumber.from(a.topBid).gt(ethers.BigNumber.from(b.topBid))) return -1
        if (ethers.BigNumber.from(a.topBid).eq(ethers.BigNumber.from(b.topBid))) return 0
        return 1
    })

}

function sortOL2H (bidPrices, result) {
    result.forEach(r => {
        let id = Object.keys(bidPrices).find(k => (r.id == k))
        if (id) {
            r.topBid = bidPrices[id]
        } else {
            r.topBid = ethers.constants.MaxInt256
        }
    })
    result.sort((a, b) => {
        if (ethers.BigNumber.from(a.topBid).lt(ethers.BigNumber.from(b.topBid))) return -1
        if (ethers.BigNumber.from(a.topBid).eq(ethers.BigNumber.from(b.topBid))) return 0
        return 1
    })
}

function sortID (result) {
    result.sort((a, b) => {
        if (ethers.BigNumber.from(a.id).lt(ethers.BigNumber.from(b.id))) return -1
        if (ethers.BigNumber.from(a.id).eq(ethers.BigNumber.from(b.id))) return 0
        return 1
    })
}

function nftIpfsLink (link) {
    if (link) {
        //return link.replace('ipfs://', 'https://dweb.link/ipfs/')
        //return link.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
        return link.replace('ipfs://', 'https://ipfs.io/ipfs/')
    }
    return link
}

function timeFormatDistance(timestamp, type) {
  const differenceInSeconds = Math.floor((Date.now() - timestamp) / 1000);
  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  const days = Math.floor(differenceInSeconds / secondsInDay);
  const hours = Math.floor(
    (differenceInSeconds % secondsInDay) / secondsInHour
  );
  const minutes = Math.floor(
    (differenceInSeconds % secondsInHour) / secondsInMinute
  );
  const seconds = Math.floor(differenceInSeconds % secondsInMinute);

    if (days > 0) {
      if(type === 'short')
          return `${days}D`;
        else 
          return `${days} days ago`;
    } else if (hours > 0) {
        if (type === "short") return `${hours}h`;
        else  return `${hours} hours ago`;
    } else if (minutes > 0) {
        if (type === "short") return `${minutes}m`;
        else return `${minutes} minutes ago`;
    } else {
        if(type === "short") return `${seconds}s`;
        else return `${seconds} seconds ago`;
  }
}

function formatDate(timestamp, pattern = "YYYY-MM-DD hh:mm:ss") {
  const date = new Date(timestamp * 1000);
  let formattedDate = "";

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  for (let i = 0; i < pattern.length; i++) {
    switch (pattern[i]) {
      case "YYYY":
        formattedDate += year;
        break;
      case "MM":
        formattedDate += month < 10 ? "0" + month : month;
        break;
      case "DD":
        formattedDate += day < 10 ? "0" + day : day;
        break;
      case "hh":
        formattedDate += hour < 10 ? "0" + hour : hour;
        break;
      case "mm":
        formattedDate += minute < 10 ? "0" + minute : minute;
        break;
      case "ss":
        formattedDate += second < 10 ? "0" + second : second;
        break;
      default:
        formattedDate += pattern[i];
    }
  }

  return formattedDate;
}

const collectionData = [
    {
        name: "BoresApeYachtClub",
        image: "",
        floorPrice: 95,
        latestPrice: 95,
        volume24h: 90,
        totalVolume: 90,
        totalSupply: 1000,
        action: { ftLink: '', nftAddress: '' }
    }
]

const nftDemo = [
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    rarity: 6145,
    buyPrice: 95,
    lastSale: 94,
    topBid: 117,
    owner: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    held: 56,
    time: 1675844696,
  },
];

const orderDemo = [
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    status: 1,
    price: 117,
    seller: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    buyer: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    status: 1,
    price: 117,
    seller: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    buyer: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    status: 1,
    price: 117,
    seller: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    buyer: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    status: 1,
    price: 117,
    seller: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    buyer: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    status: 1,
    price: 117,
    seller: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    buyer: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    time: 1675844696,
  },

  {
    name: "Bored Ape #1",
    image: "https://dev.bazarion.org/images/apes/1.png",
    status: 1,
    price: 117,
    seller: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    buyer: "0x9f74958DeA190Bdc65991F3D01e65c50ADAaD49a",
    time: 1675844696,
  },
]

const sellAction = [
  {
    action: "sell",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
  {
    action: "sell",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
  {
    action: "sell",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
  {
    action: "sell",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
  {
    action: "sell",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
  {
    action: "sell",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
]

const buyAction = [
  {
    action: "buy",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
  {
    action: "buy",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
  {
    action: "buy",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
  {
    action: "buy",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
  {
    action: "buy",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
  {
    action: "buy",
    price: 117.5,
    amount: 95,
    total: 11705,
  },
]

const openOrder = [
  {
    name: "Bored Ape #1",
    pair: "https://dev.bazarion.org/images/apes/1.png",
    type: "limit",
    side: "sell",
    price: 117,
    amount: 55,
    filled: 0,
    total: 11000,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    pair: "https://dev.bazarion.org/images/apes/1.png",
    type: "limit",
    side: "sell",
    price: 117,
    amount: 55,
    filled: 0,
    total: 11000,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    pair: "https://dev.bazarion.org/images/apes/1.png",
    type: "limit",
    side: "sell",
    price: 117,
    amount: 55,
    filled: 0,
    total: 11000,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    pair: "https://dev.bazarion.org/images/apes/1.png",
    type: "limit",
    side: "sell",
    price: 117,
    amount: 55,
    filled: 0,
    total: 11000,
    time: 1675844696,
  },
  {
    name: "Bored Ape #1",
    pair: "https://dev.bazarion.org/images/apes/1.png",
    type: "limit",
    side: "sell",
    price: 117,
    amount: 55,
    filled: 0,
    total: 11000,
    time: 1675844696,
  },
]
export default {
    truncate,
    formatLabel,
    bigToLabel,
    priceToLabel,
    bigToCommon,
    numberToCommon,
    calROI,
    feeToCommon,
    addressLink,
    txLink,
    blockLink,
    sortPH2L, sortPL2H, sortOH2L, sortOL2H, sortID,
    getFtLink,
    nftIpfsLink,
    getFTAddressFromLink,
    getNFTAddressFromLink,
    checkFTTokenAlert,
    checkNFTTokenAlert,
    priceToTotal,
    timeFormatDistance,
    formatDate,
    collectionData,
    nftDemo,
    orderDemo,
    buyAction,
    sellAction,
    openOrder
}
