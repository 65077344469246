<template>
    <div class="reward" v-if="account">
        <div class="pool-info mb-3" v-if="isReferral && !isMiningFee">
            <div class="box-info is-flex">
                <b-card class="card-item py-lg-3 pt-2 mt-1 mt-lg-0 pr-2 pl-0 px-lg-2" align="left" no-body>
                    <b-card-text class="mb-0 t-size">Total Rate</b-card-text>
                    <b-card-text v-if="!loading">{{ utils.bigToLabel(totalRate, 18, 2) }}</b-card-text>
                    <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                </b-card>
                <b-card class="card-item py-lg-3 pt-2 mt-1 mt-lg-0 pr-2 pl-0 px-lg-2" align="left" no-body>
                    <b-card-text class="mb-0 t-size">Next Reward</b-card-text>
                    <b-card-text v-if="!loading" class="i-flex s-gap">{{ utils.bigToLabel(vesting, ftDecimals, 2) }}</b-card-text>
                    <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                </b-card>
                <b-card class="card-item py-lg-3 pt-2 mt-1 mt-lg-0 pr-2 pl-0 px-lg-2" align="left" no-body>
                    <b-card-text class="mb-0 t-size">Next Halving</b-card-text>
                    <b-card-text v-if="!loading" class="i-flex s-gap"><b-link :href="utils.blockLink(chainId, nextHalving)">{{ utils.numberToCommon(nextHalving, 0) }}</b-link></b-card-text>
                    <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                </b-card>
            </div>
        </div>
        <div v-if="!loading">
            <div class="action mb-4" v-if="!isReferral">
                <div class="reward-alert">
                    <b-alert show variant="primary">Enable the Referral Program to reward Loyalty tokens for each trade at the best buy and sell prices.
                        <b-button class="enable-referral mt-3 mb-3" variant="outline-primary" :disabled="txEnableReferralProcessing" @click="enableReferral()">Enable Referral
                            <b-spinner v-if="txEnableReferralProcessing" small label="Loading..."></b-spinner>
                        </b-button>
                    </b-alert>
                </div>
            </div>
            <div class="action mb-4" v-if="isMiningFee && enableAction">
                <div class="reward-alert">
                    <b-alert show variant="primary">Enable Mining Program to earn {{ ftSymbol }} from Trading Fee Pool.
                        <b-button :disabled="!isReferral || txEnableMiningProcessing" class="enable-referral mt-3 mb-3" variant="outline-primary" @click="enableMiningFee()">Enable Mining
                            <b-spinner v-if="txEnableMiningProcessing" small label="Loading..."></b-spinner>
                        </b-button>
                    </b-alert>
                </div>
            </div>
            <div v-else>
                <div class="action mb-4 mt-3" v-if="enableAction && !referralInfo.code">
                    <div class="noti-referral-code reward-alert">
                        <b-alert
                            v-model="showRefNoti"
                            variant="success"
                            >
                            Referral Code: <strong>{{ code }}</strong> <br /><b-button @click="apply()" :disabled="txApplyProcessing" class="mt-3 mb-3" variant="outline-success">Apply Now
                            <b-spinner v-if="txApplyProcessing" small label="Loading..."></b-spinner>
                            </b-button>
                        </b-alert>

                    </div>
                </div>
                <div v-else>
                    <div class="tbl-earn">
                        <table class="table b-table">
                            <tr>
                                <td>
                                    Loyalty
                                </td>
                                <td>
                                    {{ utils.bigToLabel(referralInfo.claimable || 0, 18, 2) }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mining Rate
                                </td>
                                <td>
                                    {{ utils.bigToLabel(userFeeMining.amount || 0, 18, 2) }} <b-button class="btn-earn-now"  variant="outline-primary" @click="earn()">Boots </b-button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Est. Reward
                                </td>
                                <td>
                                    {{ utils.bigToLabel(estReward || 0, ftDecimals, 4) }} {{ ftSymbol }}
                                    <b-button class="btn-claim-now"  variant="outline-primary" @click="claim()">Claim </b-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="action mb-3 mt-3 hint-ref" v-if="enableAction">
                        <span class="text-muted small">Boost your earnings by sharing the referral code with others.</span>
                        <!--b-button class="btn-ref-code" variant="outline-primary" size="sm" @click="createCode()"><b-icon icon="plus-square"></b-icon> Ref Code</b-button-->
                    </div>
                    <b-table :fields="referralFields" :items="referrals" responsive show-empty>
                        <template #cell(code)="data">
                            {{ data.item.code }} <b-button size="sm" class="btn-copy-ref" variant="outline-primary" type="button" v-b-tooltip.focus="'Copied'" v-clipboard:copy="data.item.link">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path> </svg>
                                </span>
                            </b-button>
                        </template>
                        <template #empty="">
                            <div class="no-item" v-if="referrals.length === 0">
                                <b-card-img class="nologo" :src="require('../../assets/images/nologo.svg')" alt="Image"></b-card-img>
                                <b-card-text>No record found.</b-card-text>
                            </div>
                        </template>
                    </b-table>
                    <div class="mt-3 pb-4" v-if="iReferral > limit">
                        <b-pagination v-model="referralCurrentPage" :per-page="limit" @input="loadReferrals((referralCurrentPage - 1) * limit, referralCurrentPage * limit)" :total-rows="iReferral" align="center"></b-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <b-skeleton-table
                :rows="2"
                :columns="2"
                :table-props="{ bordered: false, striped: false }"
                ></b-skeleton-table>

        </div>
    </div>
</template>
<script>
import { FTMarket, Referral, ReferralMulticall, FeeMiningPool, FeeMiningPoolMulticall } from '@baza/sdk'
import store from '../../stores'
import { ethers } from 'ethers'

export default {
    name: 'Earn',
    components: { },
    data: () => ({
        account: '',
        marketAddress: '',
        referrals: [],
        loading: true,
        loadingMore: false,
        limit: 3,
        iReferral: 0,
        iNFT: 0,
        ftLink: '',
        nftSymbol: '',
        nftName: '',
        ft: '',
        nft: '',
        referral: {},
        feeMiningPool: {},
        referralCurrentPage: 1,
        referralFields: [
            'code',
            { key: 'count', label: 'Used' }
        ],
        enableAction: false,
        txHash: '',
        isReferral: true,
        isMiningFee: false,
        referralInfo: {},
        userFeeMining: {},
        extraReward: ethers.BigNumber.from(0),
        estReward: ethers.BigNumber.from(0),
        nextHalving: ethers.BigNumber.from(0),
        totalRate: ethers.BigNumber.from(0),
        vesting: ethers.BigNumber.from(0),
        showRefNoti: false,
        code: '',
        friend: '',
        txEnableReferralProcessing: false,
        txEnableMiningProcessing: false,
        txApplyProcessing: false
    }),
    props: ['ftDecimals', 'ftSymbol' ],
    created: async function () {
        let base = this.$route.params.base
        let quote = this.$route.params.quote

        this.code = store.getRefCode(base, quote) || this.$route.query.code || 'BAZA'

        let address = this.$route.params.address
        this.tab = this.$route.query.t || 'nft'

        try {
            this.provider = await this.getProvider(parseInt(this.$route.params.chainId))

            this.isConnect = (this.provider.connection.url !== 'metamask')
            this.address = await this.provider.getSigner().getAddress()
        } catch(e) {
            this.isConnect = true
        }

        this.account = (address) ? ethers.utils.getAddress(address) : this.address

        if (this.account === this.address) {
            this.enableAction = true
        }

        this.market = await FTMarket.setMarket(base, quote, this.provider)
        this.marketAddress = this.market.marketAddress
        this.base = this.market.baseToken
        this.quote = this.market.quoteToken
        this.isQuoteNative = this.market.isQuoteNative
        this.isBaseNative = this.market.isBaseNative

        this.referral = await Referral.setReferral(this.marketAddress, this.provider)
        this.feeMiningPool = await FeeMiningPool.setFeeMiningPool(this.marketAddress, this.provider)

        if (this.referral.address !== ethers.constants.AddressZero && this.account) {
            this.iReferral = (await this.referral.getCodesOfUserLength(this.account)).toNumber()
            this.loadReferrals(0, (this.iReferral > this.limit) ? this.limit : this.iReferral)
            await this.getReferralInfo()
        } else {
            this.isReferral = false
        }

        if (this.feeMiningPool.address !== ethers.constants.AddressZero && this.account) {
            this.isMiningFee = false
            await this.getUserFeeMining()
        } else {
            this.isMiningFee = true
        }

        if (this.isReferral && this.code) {
            let info = await this.referral.getRefConfig(this.code)
            let extra = this.referralInfo.extraReward
            let foundation = this.referralInfo.foundation
            this.friend = info[0]
            this.rate = info[1]
            this.extraReward = extra.mul(this.rate).div(1000)
            let code = await this.referral.getRefCode(this.address || ethers.constants.AddressZero)
            if (!code && this.friend !== ethers.constants.AddressZero && (this.friend !== this.account || this.account === foundation)) {
                this.showRefNoti = true
            }
        }


        this.loading = false

    }, 
    methods: {
        getUserFeeMining: async function () {
            this.totalRate = await this.referral.balanceOf(this.feeMiningPool.address)
            let calls = await FeeMiningPoolMulticall.setMulticall(this.marketAddress, this.provider)
            let feeMiningPoolInfo = await calls.getFeeMiningPoolInfo(this.account)
            this.userFeeMining = feeMiningPoolInfo.getUserInfo
            let oneEther = ethers.BigNumber.from(10).pow(18)
            this.estReward = feeMiningPoolInfo.rewardPerShare
                .mul(this.userFeeMining.amount).div(oneEther)
                .sub(this.userFeeMining.rewardDebt).add(this.userFeeMining.pendingReward)
            this.nextHalving = feeMiningPoolInfo.checkpoint.add(feeMiningPoolInfo.halvingDuration)
            this.vesting = feeMiningPoolInfo.totalReward.sub(feeMiningPoolInfo.pendingReward).div(feeMiningPoolInfo.halvingRate)
        },
        getReferralInfo: async function () {
            let calls = await ReferralMulticall.setMulticall(this.marketAddress, this.provider)
            this.referralInfo = await calls.getReferralInfo(this.account)
            this.referralInfo.reward = this.referralInfo.baseReward
            this.referralInfo.plusReward = 0
            if (this.referralInfo.code) {
                let info = await this.referral.getRefConfig(this.referralInfo.code)
                this.referralInfo.account = info[0]
                this.referralInfo.rate = info[1]
                this.referralInfo.plusReward = this.referralInfo.extraReward.mul(this.referralInfo.rate).div(1000)
                this.referralInfo.reward = this.referralInfo.baseReward.add(this.referralInfo.plusReward)
            }
        },
        reloadReferrals: async function (from, to) {
            this.iReferral = (await this.referral.getCodesOfUserLength(this.account)).toNumber()
            await this.loadReferrals(from, to)
        },
        loadReferrals: async function (from, to) {
            this.loadingMore = true
            to = (to > this.iReferral) ? this.iReferral: to
            let calls = await ReferralMulticall.setMulticall(this.marketAddress, this.provider)
            let codes = await calls.codeByIndex(this.account, from, to)
            let refConfig = await calls.getRefConfig(codes)
            let refslength = await calls.getRefsByCodeLength(codes)
            let data = []
            let router = this.$router.currentRoute
            codes.forEach((value, key) => {
                data.push({
                    code: value,
                    link: `${document.location.origin}/${router.params.chainId}/trade/${router.params.ft}/${router.params.nft}?code=${value}`,
                    rate: refConfig[key][1],
                    count: refslength[key]
                })
            })
            this.referrals = data
            this.loadingMore = false
        },
        enableReferral: async function () {
            this.txEnableReferralProcessing = true
            try {
                let tx = await this.referral.enableReferral(this.marketAddress)
                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.txHash = tx.hash
                this.$emit('enable-referral-success', {
                    txHash: tx.hash
                })
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
                this.isReferral = true
            } catch (e) {
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.show('tx-error')
            }
            this.txEnableReferralProcessing = false
        },
        enableMiningFee: async function () {
            this.txEnableMiningProcessing = true
            try {
                let tx = await this.feeMiningPool.enableMiningFee(this.marketAddress)
                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.txHash = tx.hash
                this.$emit('enable-mining-success', {
                    txHash: tx.hash
                })
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
                this.isMiningFee = false
            } catch (e) {
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.show('tx-error')
            }
            this.txEnableMiningProcessing = false
        },
        claim: async function () {
            try {
                let tx = await this.feeMiningPool.claim()
                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.txHash = tx.hash
                this.$emit('claim-success', {
                    txHash: tx.hash
                })
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
                await this.getReferralInfo()
                await this.getUserFeeMining()
            } catch (e) {
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.show('tx-error')
            }
        },
        earn: async function () {
            try {
                let tx = await this.referral.miningReward()
                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.txHash = tx.hash
                this.$emit('earn-success', {
                    txHash: tx.hash
                })
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
                await this.getReferralInfo()
                await this.getUserFeeMining()
            } catch (e) {
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.show('tx-error')
            }
        },
        apply: async function () {
            this.txApplyProcessing = true
            try {
                let tx = await this.referral.applyRefCode(this.code)
                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.$emit('apply-success', {
                    txHash: tx.hash
                })
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
                await this.getReferralInfo()
                this.iReferral = 1
                await this.loadReferrals(0, this.isReferral)
            } catch (e) {
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.show('tx-error')
            }
            this.txApplyProcessing = false
        },
        createCode: async function () {
            try {
                this.isDisabled = true
                let tx = await this.referral.createRandomRefCode()
                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.$emit('create-code-success', {
                    txHash: tx.hash
                })
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
                this.iReferral = this.iReferral + 1
                this.loadReferrals(0, (this.iReferral > this.limit) ? this.limit : this.iReferral)
            } catch (e) {
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.show('tx-error')
                this.isDisabled = false
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    .reward{
        .btn-earn-now{
            border-color: #E3FCEF;
            padding: 0px 5px;
            font-size: 10px;
            background-color: #E3FCEF;
            color: #36B37E;
            border-radius: 10px;
            line-height: 16px;
            white-space: nowrap;
            &:focus {
                box-shadow: none;
            }
            &:hover {
                box-shadow: none;
                color: white;
                background-color: #36B37E;
            }
        }
        .btn-claim-now{
            border-color: #E3FCEF;
            padding: 0px 5px;
            font-size: 10px;
            background-color: #E3FCEF;
            color: #36B37E;
            border-radius: 10px;
            line-height: 16px;
            white-space: nowrap;
            &:focus {
                box-shadow: none;
            }
            &:hover {
                box-shadow: none;
                color: white;
                background-color: #36B37E;
            }
        }
        .tbl-earn{
            .table{
                th{
                    padding: 8px 10px;
                    border-bottom: 1px solid #DFE1E6;
                }
                td {
                    padding: 5px 12px;
                    border-top: 1px solid #FFF;
                }
            }
		}
        .btn-ref-code {
            margin-left: 10px;
            color: #36B37E;
            border-radius: 30px;
            border-color: #36B37E;
            &:focus {
                box-shadow: none;
            }
            &:hover {
                box-shadow: none;
                color: white;
                background-color: #36B37E;
            }
        }
        .btn-copy-ref {
            color: #36B37E;
            border-radius: 10px;
            padding: 0px 5px;
            font-size: 10px;
            border: none;
            &:focus {
                box-shadow: none;
            }
            &:hover {
                box-shadow: none;
                color: white;
                background-color: #36B37E;
            }
        }
        .reward-alert{
            margin: 16px;
        }
        .hint-ref{
            padding-left: 12px;
        }
        .pool-info {
            border-bottom: 1px solid #dee2e6;
            margin-left: 12px;
            @media(max-width: 991px) {
                padding-bottom: 20px;
            }
        }

    }
</style>
