const { ethers } = require('ethers')
const FeeMiningPoolAbi = require('@baza/protocol/abis/baza/FeeMiningPool.json')
const ReferralFactoryAbi = require('@baza/protocol/abis/baza/ReferralFactory.json')
const { NETWORKS } = require('./constants')
const contracts = require('./contracts')

class FeeMiningPool {
    constructor(addrFeeMiningPool, network, provider, signer, factory) {
        this.feeMiningPool = new ethers.Contract(addrFeeMiningPool, FeeMiningPoolAbi, signer)
        this.address = this.feeMiningPool.address
        this.network = network
        this.provider = provider
        this.signer = signer
        this.factory = factory
    }

    static async setFeeMiningPool(market, provider) {
        let signer = provider.getSigner()
        let ok =  true
        try {
            await signer.getAddress()
        } catch (e) {
            ok = false
        }
        if (provider.connection.url !== 'metamask' || !ok) {
            let randomWallet = ethers.Wallet.createRandom()
            signer = new ethers.Wallet(randomWallet.privateKey, provider)
        }
        return provider.getNetwork().then(net => {
            const network = NETWORKS.find(n => (n.chainId === '0x' + net.chainId.toString(16))) || []
            const contract = contracts.find(c => (c.ChainId === net.chainId))

            let feeMiningPoolFactory = new ethers.Contract(contract.ReferralFactory, ReferralFactoryAbi, signer)

            return feeMiningPoolFactory.getMiningPool(market).then((feeMiningPool) => {
                return new FeeMiningPool(feeMiningPool, network, provider, signer, feeMiningPoolFactory)
            })

        })
    }

    enableMiningFee(market) {
        return this.factory.createMiningPool(market)
    }

    async claim(amount = 0) {
        if (amount === 0) {
            let userInfo = await this.feeMiningPool.getUserInfo(await this.signer.getAddress())
            amount = userInfo.amount
        }
        return this.feeMiningPool.claim(amount)
    }

    getUserInfo(addr) {
        return this.feeMiningPool.getUserInfo(addr)
    }


}

module.exports = FeeMiningPool
