module.exports = {
    NETWORKS: [
        {
            chainId: '0x38',
            chainName: 'BNB Chain',
            nativeCurrency: { name: 'BNB Chain', symbol: 'BNB', decimals: 18, wrappedToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c' },
            rpcUrls: ['https://bsc-dataseed1.bnbchain.org'],
            // rpcUrls: ['https://summer-damp-mountain.bsc.quiknode.pro/2e0b2585ff601a7c5a6a214c42ae7c27054a8773/'],
            blockExplorerUrls: ['https://bscscan.com'],
            isSupported: true,
            logoURI: 'https://github.com/bazarion/assets/raw/main/chains/binancesmartchain/logo.png'
        },
        {
            chainId: '0x61',
            chainName: 'BNB Chain Testnet',
            nativeCurrency: { name: "BNB Chain Testnet", symbol: 'tBNB', decimals: 18, wrappedToken: '0x1e33833a035069f42d68D1F53b341643De1C018D' },
            rpcUrls: ['https://bsc-testnet-rpc.publicnode.com'],
            blockExplorerUrls: ['https://testnet.bscscan.com'],
            iconUrls: ['future'],
            isSupported: false
        }
    ],
    MULTICALLS: [
        {
            chainId: '0x38',
            address: '0x41263cBA59EB80dC200F3E2544eda4ed6A90E76C'
        },
        {
            chainId: '0x61',
            address: '0x6e5BB1a5Ad6F68A8D7D6A5e47750eC15773d6042'
        }
    ]
}
