const { ethers } = require('ethers')
const FeeMiningPoolAbi = require('@baza/protocol/abis/baza/FeeMiningPool.json')
const ReferralFactoryAbi = require('@baza/protocol/abis/baza/ReferralFactory.json')
const { Contract, Provider } = require('ethcall')
const contracts = require('./contracts')
const { MULTICALLS } = require('./constants')

class FeeMiningPoolMulticall {
    constructor(addrFeeMiningPool, chainId, provider) {
        this.feeMiningPoolContract = new Contract(addrFeeMiningPool, FeeMiningPoolAbi)
        this.provider = provider
        this.chainId = chainId
        this.address = addrFeeMiningPool
    }

    static setMulticall(market, provider) {
        const ethcallProvider = new Provider()
        return ethcallProvider.init(provider).then(() => {
            return provider.getNetwork().then(net => {
                const multi = MULTICALLS.find(n => (n.chainId === '0x' + net.chainId.toString(16)))
                const contract = contracts.find(c => (c.ChainId === net.chainId))
                ethcallProvider.multicall = { address: multi.address, block: 0 }

                let signer = provider.getSigner()
                if (provider.connection.url !== 'metamask') {
                    let randomWallet = ethers.Wallet.createRandom()
                    signer = new ethers.Wallet(randomWallet.privateKey, provider)
                }

                return signer.getAddress().then(() => {
                    let referralFactory = new ethers.Contract(contract.ReferralFactory, ReferralFactoryAbi, signer)
                    return referralFactory.getMiningPool(market).then((addrFeeMiningPool) => {
                        return new ReferralMulticall(addrFeeMiningPool, net.chainId, ethcallProvider)
                    })
                }).catch(() => {
                    let randomWallet = ethers.Wallet.createRandom()
                    signer = new ethers.Wallet(randomWallet.privateKey, provider)
                    let referralFactory = new ethers.Contract(contract.ReferralFactory, ReferralFactoryAbi, signer)
                    return referralFactory.getMiningPool(market).then((addrFeeMiningPool) => {
                        return new FeeMiningPoolMulticall(addrFeeMiningPool, net.chainId, ethcallProvider)
                    })
                })
            })
        })
    }

    async getFeeMiningPoolInfo(addr = ethers.constants.ZeroAddress) {
        let calls = []
        calls.push(this.feeMiningPoolContract.rewardPerShare())
        calls.push(this.feeMiningPoolContract.pendingReward())
        calls.push(this.feeMiningPoolContract.getUserInfo(addr))
        calls.push(this.feeMiningPoolContract.checkpoint())
        calls.push(this.feeMiningPoolContract.HALVING_DURATION())
        calls.push(this.feeMiningPoolContract.HALVING_RATE())
        calls.push(this.feeMiningPoolContract.balanceOf(this.address))
        let data = await this.provider.all(calls)
        let getUserInfo = data[2]
        let rewardPerShare = data[0]
        return {
            rewardPerShare: data[0],
            pendingReward: data[1],
            getUserInfo: data[2],
            checkpoint: data[3],
            halvingDuration: data[4],
            halvingRate: data[5],
            totalReward: data[6]
        }
    }
}

module.exports = FeeMiningPoolMulticall
