<template>
    <div class="buy-base">
        <b-card no-body class="box-action mb-3">
            <b-card-body>
                <b-form autocomplete="off">
                    <b-form-group
                        label="Amount"
                        label-for="max-amount"
                        >
                        <b-form-input id="amount" input-id="max-amount" v-model="amount" required placeholder=""></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Price"
                        label-for="base-price"
                        >
                        <b-form-input
                        id="base-price"
                        v-model="price"
                        readonly
                        required
                        ></b-form-input>
                        <b-card-text class="text-right balance">
                            <span>{{ parseFloat(amount * parseFloat(price)).toFixed(4) }} {{ quoteSymbol }}</span>&nbsp;<b-icon-arrow-right></b-icon-arrow-right>&nbsp;<span>{{ amount }} {{ baseSymbol }}</span>
                        </b-card-text>
                    </b-form-group>

                    <div v-if="!loadingData">
                    <b-btn class="btn-custom btn-cancel mr-3" @click="$emit('buy-cancel')">
                        Cancel
                    </b-btn>

                <b-btn 
                    class="btn-custom btn-buy"
                    :disabled='isDisabled'
                    @click="buy()"
                    >
                    Buy <b-spinner v-if="isDisabled" small label="Loading..."></b-spinner>
                </b-btn>
                </div>
                <b-skeleton v-else type="button"></b-skeleton>

                </b-form>
            </b-card-body>
        </b-card>
    </div>

</template>
<script>
import { ethers } from 'ethers'
import { FTMarket, FTMarketMulticall, FT } from '@baza/sdk'

export default {
    name: 'P2PBuy',
    data: () => ({
        market: '',
        price: 0,
        amount: 0,
        balance: ethers.BigNumber.from(0),
        isQuoteApproved: false,
        quote: '',
        quoteDecimals: 18,
        quoteSymbol: '',
        base: '',
        baseDecimals: 18,
        baseSymbol: '',
        isQuoteNative: false,
        isDisabled: false,
        loadingData: true
    }),
    props: [ 'baseLink', 'quoteLink', 'askData' ],
    created: async function () {
        this.market = await FTMarket.setMarket(this.baseLink, this.quoteLink, this.provider)
        let calls = await FTMarketMulticall.setMulticall(this.market.baseToken, this.market.quoteToken, this.provider)

        this.marketAddress = this.market.marketAddress
        this.quote = this.market.quoteToken
        this.isQuoteNative = this.market.isQuoteNative
        this.address = await this.provider.getSigner().getAddress()

        let { baseDecimals, baseSymbol } = await calls.getBaseInfo()
        this.baseDecimals = baseDecimals
        this.baseSymbol = baseSymbol

        let { quoteSymbol, quoteDecimals, balance } = await calls.getQuoteInfo()
        this.quoteDecimals = quoteDecimals
        this.quoteSymbol = quoteSymbol
        this.balance = balance

        this.price = ethers.utils.formatUnits(this.askData.price, quoteDecimals)
        this.amount = ethers.utils.formatUnits(this.askData.amount, baseDecimals)

        let quoteFT = new FT(this.quote, this.provider)
        this.isQuoteApproved = this.isQuoteNative || !(await quoteFT.allowance(this.address, this.marketAddress)).eq(0)

        this.loadingData = false

    }, 
    methods: {
        async quoteApprove() {
            let quoteFT = new FT(this.quote, this.provider)
            let tx = await quoteFT.approve(this.marketAddress)
            this.$bvToast.show('tx-confirm')
            await tx.wait()
            this.$bvToast.show('tx-success')
            this.$bvToast.hide('tx-confirm')
            this.isQuoteApproved = true
        },
        async buy () {
            try {
                this.isDisabled = true
                if (!this.isQuoteApproved) {
                    await this.quoteApprove()
                }
                let amount = ethers.utils.parseUnits(this.amount, this.baseDecimals)
                let price = ethers.utils.parseUnits(this.price, this.quoteDecimals)
                let tx = await this.market.buy(this.askData.orderId, amount, price)

                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.$emit('buy-success', {
                    txHash: tx.hash,
                    price: price,
                    txText: 'bought'
                })
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
            } catch (e) {
                console.log(e)
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.show('tx-error')
                this.isDisabled = false
            }
        },
    }
};
</script>
<style lang="scss">
    .buy-base{        
        .nft-name{
            font-size: 30px;
            font-weight: 700;
        }
        .box-action{
            background-color: #F4F5F7;
            border-radius: 8px;
            border: none;
            label{
                color:#9D22C1;
                text-transform: uppercase;
                font-size: 12px;
            }
            #base-price,#amount{
                height: 56px;
                border-radius: 8px;
                border: 1px solid #DFE1E6;
            }
            .balance{
                font-size: 14px;
                color: #5E6C84;
                font-weight: normal;
                margin-top: 10px;
                justify-content: end;
            }
        }
        .btn-custom{
            padding: 8px 16px;
            border: none;
            font-weight: 400;
            border-radius: 30px;
            min-width: 80px;
            &:focus{
                box-shadow: none;
            }  
        }
        .btn-approve,.btn-buy{
            color: #fff;
            background-color: #36B37E;
            &:hover{
                background-color: #36B37E;
            }
            &.disabled,&:disabled{
                background-color: #36B37E;
            }   
        }
        .btn-cancel{
            color: #196EC2;
            background-color: rgba(25, 110, 194, 0.1);
            &:hover{
                background-color: rgba(25, 110, 194, 0.295);
                color: #196EC2;
            }
        }
    }
</style>
