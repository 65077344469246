const { ethers } = require('ethers')
const ReferralAbi = require('@baza/protocol/abis/baza/Referral.json')
const ReferralFactoryAbi = require('@baza/protocol/abis/baza/ReferralFactory.json')
const { NETWORKS } = require('./constants')
const contracts = require('./contracts')

class Referral {
    constructor(addrReferral, network, provider, signer, factory) {
        this.referral = new ethers.Contract(addrReferral, ReferralAbi, signer)
        this.address = this.referral.address
        this.network = network
        this.provider = provider
        this.signer = signer
        this.factory = factory
    }

    static async setReferral(market, provider) {
        let signer = provider.getSigner()
        let ok =  true
        try {
            await signer.getAddress()
        } catch (e) {
            ok = false
        }
        if (provider.connection.url !== 'metamask' || !ok) {
            let randomWallet = ethers.Wallet.createRandom()
            signer = new ethers.Wallet(randomWallet.privateKey, provider)
        }
        return provider.getNetwork().then(net => {
            const network = NETWORKS.find(n => (n.chainId === '0x' + net.chainId.toString(16))) || []
            const contract = contracts.find(c => (c.ChainId === net.chainId))

            let referralFactory = new ethers.Contract(contract.ReferralFactory, ReferralFactoryAbi, signer)

            return referralFactory.getReferral(market).then((referral) => {
                return new Referral(referral, network, provider, signer, referralFactory)
            })

        })
    }

    enableReferral(market) {
        return this.factory.createReferral(market)
    }

    createRefCode(code, rate) {
        return this.referral.createRefCode(code, rate)
    }

    makeRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    createRandomRefCode() {
        let code = this.makeRandomString(8)
        return this.referral.createRefCode(code, 500)
    }

    applyRefCode(code) {
        let randomString = this.makeRandomString(8)
        return this.referral.applyRefCode(code, randomString)
    }

    async claim(amount = 0) {
        if (amount === 0) {
            amount = await this.referral.getClaimable(await this.signer.getAddress())
        }
        return this.referral.claim(amount)
    }

    async miningReward(amount = 0) {
        if (amount === 0) {
            amount = await this.referral.getClaimable(await this.signer.getAddress())
        }
        return this.referral.miningReward(amount)
    }

    getCodesOfUserLength(addr) {
        return this.referral.getCodesOfUserLength(addr)
    }

    getRefConfig(code) {
        return this.referral.getRefConfig(code)
    }

    extraReward() {
        return this.referral.EXTRA_REWARD()
    }

    baseReward() {
        return this.referral.BASE_REWARD()
    }

    getRefCode(addr) {
        return this.referral.getRefCode(addr)
    }

    balanceOf(addr) {
        return this.referral.balanceOf(addr)
    }

}

module.exports = Referral
