const { ethers } = require('ethers')
const ReferralAbi = require('@baza/protocol/abis/baza/Referral.json')
const ReferralFactoryAbi = require('@baza/protocol/abis/baza/ReferralFactory.json')
const { Contract, Provider } = require('ethcall')
const contracts = require('./contracts')
const { NETWORKS, MULTICALLS } = require('./constants')

class ReferralMulticall {
    constructor(referral, chainId, provider) {
        this.referralContract = new Contract(referral, ReferralAbi)
        this.provider = provider
        this.chainId = chainId
    }

    static setMulticall(market, provider) {
        const ethcallProvider = new Provider()
        return ethcallProvider.init(provider).then(() => {
            return provider.getNetwork().then(net => {
                const multi = MULTICALLS.find(n => (n.chainId === '0x' + net.chainId.toString(16)))
                const contract = contracts.find(c => (c.ChainId === net.chainId))
                ethcallProvider.multicall = { address: multi.address, block: 0 }

                let signer = provider.getSigner()
                if (provider.connection.url !== 'metamask') {
                    let randomWallet = ethers.Wallet.createRandom()
                    signer = new ethers.Wallet(randomWallet.privateKey, provider)
                }

                return signer.getAddress().then(() => {
                    let referralFactory = new ethers.Contract(contract.ReferralFactory, ReferralFactoryAbi, signer)
                    return referralFactory.getReferral(market).then((referral) => {
                        return new ReferralMulticall(referral, net.chainId, ethcallProvider)
                    })
                }).catch(() => {
                    let randomWallet = ethers.Wallet.createRandom()
                    signer = new ethers.Wallet(randomWallet.privateKey, provider)
                    let referralFactory = new ethers.Contract(contract.ReferralFactory, ReferralFactoryAbi, signer)
                    return referralFactory.getReferral(market).then((referral) => {
                        return new ReferralMulticall(referral, net.chainId, ethcallProvider)
                    })
                })
            })
        })
    }

    async codeByIndex(user, from, to) {
        let calls = []
        for (let i = from; i < to; i++) {
            calls.push(this.referralContract.getCodesOfUser(user, i))
        }
        let data = await this.provider.all(calls)
        return data
    }

    async getRefConfig(codes) {
        let calls = []
        for (let i = 0; i < codes.length; i++) {
            calls.push(this.referralContract.getRefConfig(codes[i]))
        }
        let data = await this.provider.all(calls)
        return data
    }

    async getRefsByCodeLength(codes) {
        let calls = []
        for (let i = 0; i < codes.length; i++) {
            calls.push(this.referralContract.getRefsByCodeLength(codes[i]))
        }
        let data = await this.provider.all(calls)
        return data
    }

    async getReferralInfo(addr) {
        let calls = []
        calls.push(this.referralContract.getClaimable(addr))
        calls.push(this.referralContract.balanceOf(addr))
        calls.push(this.referralContract.getRefCode(addr))
        calls.push(this.referralContract.BASE_REWARD())
        calls.push(this.referralContract.EXTRA_REWARD())
        calls.push(this.referralContract.foundation())
        let data = await this.provider.all(calls)
        return {
            balance: data[1],
            claimable: data[0],
            code: data[2],
            baseReward: data[3],
            extraReward: data[4],
            foundation: data[5]
        }
    }

}

module.exports = ReferralMulticall
